@charset "UTF-8";

/* mediaquery
============================================================= */
// 768以降
@mixin mq-pc {
  @media screen and (min-width: $break-small + 1), print {
    @content;
  }
}
// 768まで
@mixin mq-sp {
  @media screen and (max-width: $break-small) {
    @content;
  }
}
//1366px以降
@mixin mq-min {
  @media screen and (min-width: $min-basis) {
    @content;
  }
}
// 768以降
@mixin mq-max {
  @media screen and (min-width: $max-basis), print {
    @content;
  }
}
//1441px以降
@mixin mq-wide {
  @media screen and (min-width: $pc-basis) {
    @content;
  }
}
//768〜1440pxまで
@mixin pc-only-with {
  @media screen and (min-width: $min-basis) and (max-width: $pc-basis) {
    @content;
  }
}

@mixin mq-hover {
  @media (hover: hover),
    screen and (-ms-high-contrast: active),
    screen and (-ms-high-contrast: none) {
    @content;
  }
}

/* hover
============================================================= */

@mixin fadeover {
  @include mq-hover {
    transition-duration: 0.3s;
    text-decoration: none !important;
    &:hover {
      opacity: 0.7;
    }
  }
}

/* other
============================================================= */

@mixin clearfix {
  &::after {
    display: block;
    clear: both;
    content: "";
  }
}

@mixin contents {
  @include mq-pc {
    margin-left: auto;
    margin-right: auto;
    max-width: rem(1076);
  }
}

@mixin jp {
  html[lang="ja"] {
    @content;
  }
}

@mixin ie11($selector) {
  _:-ms-lang(x)::-ms-backdrop,
  #{$selector} {
    @content;
  }
}
