/* base */
/* Canvas Size */
/* PC Responsive Width */
/* Color */
/* Font */
/* Responsive
============================================================= */
/* Exclude
============================================================= */
/* px to rem
============================================================= */
/* px to em
============================================================= */
/* WebFont
============================================================= */
/* Color
============================================================= */
/* mediaquery
============================================================= */
/* hover
============================================================= */
/* other
============================================================= */
/**
 * index
 */
body[id="index"] .l-wrapper,
body[id="index"] .l-container {
  margin-top: 0;
  padding-top: 0;
}

body[id="index"] .headerWrap {
  position: fixed;
}

body[id="index"] #header {
  position: fixed;
  width: 100%;
  z-index: 10000;
}

@media screen and (min-width: 768px), print {
  body[id="index"] #header.-headerFixed {
    animation: unset;
  }
}

body[id="index"] #header.-topHeaderfirst {
  background-color: unset;
}

@media screen and (min-width: 768px), print {
  body[id="index"] #header.-topHeaderfirst {
    padding-top: 4.9rem;
  }
}

@media screen and (min-width: 768px), print {
  body[id="index"] #header.-topHeaderfirst .spMenuBtn__icon span {
    background-color: white;
  }
}

body[id="index"] #header.-topHeaderfirst .header__logo {
  display: none;
}

body[id="index"] #header.-topHeaderfirst .languageNav li a {
  color: white;
  opacity: 0.5;
}

body[id="index"] #header.-topHeaderfirst .languageNav li a:hover {
  opacity: 1;
}

body[id="index"] #header.-topHeaderfirst .languageNav li.-active a {
  opacity: 1;
}

body[id="index"] #header.-topHeaderfirst .languageNav li:after {
  background-color: white;
  opacity: 0.5;
}

body[id="index"] #header.-topHeaderfirstMenu {
  padding-top: 4.9rem;
}

body[id="index"] #header .header__logo.-topLogoNon {
  display: none;
}

body[id="index"].-spMenuOpen #header {
  background-color: #fafafa !important;
}

body[id="index"].-spMenuOpen .header__logo.-topLogoNon {
  display: unset !important;
}

body[id="index"].-spMenuOpen .-topHeaderfirst .spMenuBtn__icon span {
  background-color: #000;
}

body[id="index"].-spMenuOpen .-topHeaderfirst .header__logo {
  display: block !important;
}

body[id="index"].-spMenuOpen .-topHeaderfirst .languageNav li a {
  color: #000 !important;
}

body[id="index"].-spMenuOpen .-topHeaderfirst .languageNav li:after {
  background-color: #000 !important;
}

body[id="index"] .mv {
  position: relative;
}

@media screen and (min-width: 768px), print {
  body[id="index"] .mv {
    height: 100vh;
    display: flex;
  }
}

@media screen and (max-width: 767px) {
  body[id="index"] .mv {
    width: 100%;
    position: relative;
    display: block;
  }
}

body[id="index"] .mv .swiper-container {
  height: 100%;
}

@media screen and (min-width: 768px), print {
  body[id="index"] .mvImgBox {
    width: 63.8888888889vw;
  }
}

@media screen and (max-width: 767px) {
  body[id="index"] .mvImgBox {
    width: 100%;
    bottom: 0;
    height: 52.4%;
  }
}

body[id="index"] .mvTxtBox {
  background-color: #fafafa;
}

@media screen and (min-width: 768px), print {
  body[id="index"] .mvTxtBox {
    width: 36.1111111111vw;
    align-items: center;
    display: flex;
    justify-content: center;
  }
}

@media screen and (max-width: 767px) {
  body[id="index"] .mvTxtBox {
    width: 100%;
    height: 47.6%;
    top: 0;
    display: flex;
    align-items: center;
  }
}

body[id="index"] .mvTxt.-list {
  position: absolute;
  display: flex;
}

@media screen and (min-width: 768px), print {
  body[id="index"] .mvTxt.-list {
    left: 3.125vw;
    top: 3.9583333333vw;
  }
}

@media screen and (min-width: 1441px) {
  body[id="index"] .mvTxt.-list {
    left: 4.5rem;
    top: 5.7rem;
  }
}

body[id="index"] .mvTxt.-item {
  letter-spacing: 0.2em;
}

@media screen and (min-width: 768px), print {
  body[id="index"] .mvTxt.-item {
    font-size: 0.9027777778vw;
  }
}

@media screen and (min-width: 1441px) {
  body[id="index"] .mvTxt.-item {
    font-size: 1.3rem;
  }
}

body[id="index"] .mvLogo {
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  position: relative;
}

@media screen and (min-width: 768px), print {
  body[id="index"] .mvLogo {
    width: 19.6694444444vw;
    top: -2rem;
  }
}

@media screen and (max-width: 767px) {
  body[id="index"] .mvLogo {
    width: 70.536vw;
  }
}

body[id="index"] .mvLogo.-logo {
  text-align: center;
}

@media screen and (min-width: 768px), print {
  body[id="index"] .mvLogo.-img {
    width: 19.6694444444vw;
  }
}

@media screen and (min-width: 1441px) {
  body[id="index"] .mvLogo.-img {
    width: 28.324rem;
  }
}

body[id="index"] .mvLogo.-txt {
  display: flex;
  letter-spacing: 0.05em;
  justify-content: center;
  line-height: 1;
  white-space: nowrap;
}

@media screen and (min-width: 768px), print {
  body[id="index"] .mvLogo.-txt {
    margin-bottom: 5rem;
    font-size: 0.9027777778vw;
  }
}

@media screen and (min-width: 1441px) {
  body[id="index"] .mvLogo.-txt {
    font-size: 1.3rem;
  }
}

@media screen and (max-width: 767px) {
  body[id="index"] .mvLogo.-txt {
    font-size: 3.4666666667vw;
    margin-bottom: 10.1333333333vw;
  }
}

@media screen and (min-width: 768px), print {
  body[id="index"] .mvLogo.-txt .left {
    margin-right: 0.9027777778vw;
  }
}

@media screen and (min-width: 1441px) {
  body[id="index"] .mvLogo.-txt .left {
    margin-right: 1.3rem;
  }
}

@media screen and (max-width: 767px) {
  body[id="index"] .mvLogo.-txt .left {
    margin-right: 2.1333333333vw;
  }
}

body[id="index"] .section__intro {
  letter-spacing: 0.2em;
  width: 100%;
  text-align: center;
}

@media screen and (min-width: 768px), print {
  body[id="index"] .section__intro {
    margin-top: 18.625rem;
    margin-bottom: 18.625rem;
    font-size: 2.2rem;
    margin-left: auto;
    margin-right: auto;
  }
}

@media screen and (max-width: 767px) {
  body[id="index"] .section__intro {
    font-size: 4.8vw;
    margin-bottom: 18.3333333333vw;
    margin-top: 18.3333333333vw;
    margin-left: auto;
    margin-right: auto;
    width: 75vw;
    text-align: left;
  }
}

body[id="index"] .section__about, body[id="index"] .section__hikibaku {
  margin-right: auto;
  margin-left: auto;
  width: 100%;
}

@media screen and (min-width: 768px), print {
  body[id="index"] .section__about, body[id="index"] .section__hikibaku {
    max-width: 83.3333333333vw;
    margin-bottom: 17.5rem;
  }
}

@media screen and (min-width: 1441px) {
  body[id="index"] .section__about, body[id="index"] .section__hikibaku {
    max-width: 120rem;
  }
}

@media screen and (max-width: 767px) {
  body[id="index"] .section__about, body[id="index"] .section__hikibaku {
    margin-bottom: 20vw;
  }
}

body[id="index"] .section__about .con.-wrap, body[id="index"] .section__hikibaku .con.-wrap {
  display: flex;
  align-items: center;
}

@media screen and (max-width: 767px) {
  body[id="index"] .section__about .con.-wrap, body[id="index"] .section__hikibaku .con.-wrap {
    flex-wrap: wrap;
    flex-direction: column;
  }
}

@media screen and (min-width: 768px), print {
  body[id="index"] .section__about .con.-txtWrap, body[id="index"] .section__hikibaku .con.-txtWrap {
    width: 27.7777777778vw;
  }
}

@media screen and (max-width: 767px) {
  body[id="index"] .section__about .con.-txtWrap, body[id="index"] .section__hikibaku .con.-txtWrap {
    padding-right: 6.6666666667vw;
    padding-left: 6.6666666667vw;
    order: 2;
  }
}

@media screen and (min-width: 768px), print {
  body[id="index"] .section__about .con.-img, body[id="index"] .section__hikibaku .con.-img {
    width: 55.5555555556vw;
  }
}

@media screen and (max-width: 767px) {
  body[id="index"] .section__about .con.-img, body[id="index"] .section__hikibaku .con.-img {
    order: 1;
    margin-bottom: 13.3333333333vw;
  }
}

@media screen and (min-width: 768px), print {
  body[id="index"] .section__about .con.section__title, body[id="index"] .section__hikibaku .con.section__title {
    margin-bottom: 9rem;
  }
}

@media screen and (max-width: 767px) {
  body[id="index"] .section__about .con.section__title, body[id="index"] .section__hikibaku .con.section__title {
    margin-bottom: 8.8666666667vw;
  }
}

@media screen and (min-width: 768px), print {
  body[id="index"] .section__about .con .moreLink, body[id="index"] .section__hikibaku .con .moreLink {
    margin-top: 8.3rem;
  }
}

@media screen and (max-width: 767px) {
  body[id="index"] .section__about .con .moreLink, body[id="index"] .section__hikibaku .con .moreLink {
    margin-top: 8.3333333333vw;
  }
}

@media screen and (min-width: 768px), print {
  body[id="index"] .section__about .section__title {
    margin-bottom: 8.3rem;
  }
}

@media screen and (max-width: 767px) {
  body[id="index"] .section__about .section__title {
    margin-bottom: 9vw;
  }
}

@media screen and (min-width: 768px), print {
  body[id="index"] .section__about .con.-txtWrap {
    padding-right: 7.7777777778vw;
  }
}

@media screen and (min-width: 768px), print {
  body[id="index"] .section__hikibaku .con.-txtWrap {
    padding-left: 7.7777777778vw;
  }
}

body[id="index"] .section__works {
  color: white;
  background-color: #1a1a1a;
}

@media screen and (min-width: 768px), print {
  body[id="index"] .section__works {
    padding-top: 12.5rem;
    padding-bottom: 15rem;
    margin-bottom: 15rem;
  }
}

@media screen and (max-width: 767px) {
  body[id="index"] .section__works {
    padding-top: 16.8vw;
    padding-bottom: 16vw;
    margin-bottom: 20vw;
  }
}

body[id="index"] .section__works .moreLinkTxt {
  color: white;
}

@media screen and (min-width: 768px), print {
  body[id="index"] .section__works .moreLinkTxt {
    margin-left: 5.2083333333vw;
  }
}

@media screen and (max-width: 767px) {
  body[id="index"] .section__works .moreLink {
    margin-left: auto;
  }
}

body[id="index"] .section__works .titleMoreLinkWrap {
  display: flex;
  align-items: center;
}

@media screen and (min-width: 768px), print {
  body[id="index"] .section__works .titleMoreLinkWrap {
    margin-bottom: 13rem;
  }
}

@media screen and (max-width: 767px) {
  body[id="index"] .section__works .titleMoreLinkWrap {
    margin-bottom: 17.3333333333vw;
  }
}

body[id="index"] .section__works .section__title {
  margin-bottom: 0;
}

@media screen and (max-width: 767px) {
  body[id="index"] .section__works .imgWrap {
    overflow: hidden;
    min-height: 57.8666666667vw;
    max-height: 57.8666666667vw;
  }
}

body[id="index"] .section__works .imgItem {
  position: relative;
}

body[id="index"] .section__works .imgItem a {
  display: block;
}

@media screen and (min-width: 768px), print {
  body[id="index"] .section__works .imgItem:not(:last-child) {
    margin-bottom: 12.5rem;
  }
}

@media screen and (max-width: 767px) {
  body[id="index"] .section__works .imgItem:not(:last-child) {
    margin-bottom: 15.5333333333vw;
  }
}

@media screen and (min-width: 768px), print {
  body[id="index"] .section__works .imgItem:nth-child(odd) {
    margin-right: auto;
  }
}

@media screen and (min-width: 768px), print {
  body[id="index"] .section__works .imgItem:nth-child(odd) .caption {
    right: -2.8rem;
  }
}

@media screen and (min-width: 768px), print {
  body[id="index"] .section__works .imgItem:nth-child(even) {
    margin-left: auto;
  }
}

@media screen and (min-width: 768px), print {
  body[id="index"] .section__works .imgItem:nth-child(even) .caption {
    left: -2.8rem;
  }
}

@media screen and (min-width: 768px), print {
  body[id="index"] .section__works .imgItem.-yoko {
    width: 55.5555555556vw;
  }
}

@media screen and (min-width: 1441px) {
  body[id="index"] .section__works .imgItem.-yoko {
    width: 83rem;
  }
}

@media screen and (min-width: 768px), print {
  body[id="index"] .section__works .imgItem.-tate {
    width: 40.6944444444vw;
  }
}

@media screen and (min-width: 1441px) {
  body[id="index"] .section__works .imgItem.-tate {
    width: 61.7rem;
  }
}

body[id="index"] .section__works .imgItem .caption {
  color: white;
}

@media screen and (min-width: 768px), print {
  body[id="index"] .section__works .imgItem .caption {
    writing-mode: vertical-rl;
    top: 0;
    position: absolute;
    line-height: 1.25;
    letter-spacing: 0.2em;
    font-size: 1.3rem;
  }
}

@media screen and (max-width: 767px) {
  body[id="index"] .section__works .imgItem .caption {
    margin-top: 3vw;
  }
}

@media screen and (min-width: 768px), print {
  body[id="index"] .section__contact {
    margin-bottom: 17.3rem;
  }
}

@media screen and (max-width: 767px) {
  body[id="index"] .section__contact {
    margin-bottom: 20.8vw;
  }
}

@media screen and (min-width: 768px), print {
  body[id="index"] .section__contact .section__title {
    margin-bottom: 6.8rem;
  }
}

@media screen and (max-width: 767px) {
  body[id="index"] .section__contact .section__title {
    margin-bottom: 10.5vw;
  }
}

body[id="index"] .section__insta .section__title {
  margin-bottom: 0;
  letter-spacing: 0.05em;
}

@media screen and (min-width: 768px), print {
  body[id="index"] .section__insta .titleInstaIdImgWrap {
    margin-bottom: 7.5rem;
    display: flex;
    align-items: end;
  }
}

@media screen and (max-width: 767px) {
  body[id="index"] .section__insta .titleInstaIdImgWrap {
    margin-bottom: 12vw;
  }
}

body[id="index"] .section__insta .titleInstaIdImgWrap figure {
  display: flex;
}

@media screen and (min-width: 768px), print {
  body[id="index"] .section__insta .titleInstaIdImgWrap figure {
    margin-left: 7.3rem;
    max-width: 22.5rem;
    width: 100%;
    height: 100%;
  }
}

@media screen and (max-width: 767px) {
  body[id="index"] .section__insta .titleInstaIdImgWrap figure {
    margin-top: 4.5333333333vw;
    width: 55.4666666667vw;
  }
}

body[id="index"] .section__insta .titleInstaIdImgWrap figure a {
  display: flex;
}

body[id="index"] .section__insta .titleInstaIdImgWrap figure img {
  width: 100%;
}

body[id="index"] .section__insta .instaCon .instaItem.-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

@media screen and (min-width: 768px), print {
  body[id="index"] .section__insta .instaCon .instaItem.-list {
    margin: -1.0416666667vw;
  }
}

@media screen and (min-width: 1441px) {
  body[id="index"] .section__insta .instaCon .instaItem.-list {
    margin: -1.5rem;
  }
}

@media screen and (max-width: 767px) {
  body[id="index"] .section__insta .instaCon .instaItem.-list {
    margin: -2.0666666667vw;
  }
}

body[id="index"] .section__insta .instaCon .instaItem.-item {
  overflow: hidden;
}

@media screen and (min-width: 768px), print {
  body[id="index"] .section__insta .instaCon .instaItem.-item {
    width: 16.6666666667vw;
    height: 16.6666666667vw;
    padding: 1.0416666667vw;
  }
}

@media screen and (min-width: 1441px) {
  body[id="index"] .section__insta .instaCon .instaItem.-item {
    width: 24rem;
    height: 24rem;
    padding: 1.5rem;
  }
}

@media screen and (max-width: 767px) {
  body[id="index"] .section__insta .instaCon .instaItem.-item {
    width: 45.0026666667vw;
    height: 45.0026666667vw;
    padding: 2vw;
  }
}

body[id="index"] .section__insta .instaCon .instaItem.-item a {
  display: block;
  width: 100%;
  height: 100%;
}

@media screen and (max-width: 767px) {
  body[id="index"] .section__insta .instaCon .instaItem.-item:nth-child(n + 5) {
    display: none !important;
  }
}

@media screen and (min-width: 768px), print {
  body[id="index"] .-contactBox .btnWrap {
    margin-top: 6.3rem;
  }
}

@media screen and (max-width: 767px) {
  body[id="index"] .-contactBox .btnWrap {
    margin-top: 8.8666666667vw;
  }
}

@media screen and (min-width: 768px), print {
  body[id="index"] .footer__contents {
    padding-top: 22.5rem;
  }
}

@media screen and (max-width: 767px) {
  body[id="index"] .footer__contents {
    padding-top: 40vw;
  }
}
