@charset "UTF-8";
@import "../common/_settings/_setting";

/**
 * index
 */
body[id="index"] {
  .l-wrapper,
  .l-container {
    margin-top: 0;
    padding-top: 0;
  }
  .headerWrap {
    position: fixed;
  }
  #header {
    position: fixed;
    width: 100%;
    z-index: 10000;
    &.-headerFixed {
      @include mq-pc {
        animation: unset;
      }
    }
    &.-topHeaderfirst {
      background-color: unset;
      @include mq-pc {
        padding-top: 4.9rem;
      }
      @include mq-sp {
        .spMenuBtn {
          // display: none !important;
        }
      }
      .spMenuBtn__icon span {
        @include mq-pc {
          background-color: white;
        }
      }
      .header__logo {
        display: none;
      }
      .languageNav {
        li {
          a {
            color: white;
            opacity: 0.5;
            &:hover {
              opacity: 1;
            }
          }
          &.-active {
            a {
              opacity: 1;
            }
          }
          &:after {
            background-color: white;
            opacity: 0.5;
          }
        }
      } //languageNav
    } //topHeaderWhite
    &.-topHeaderfirstMenu {
      padding-top: 4.9rem;
    } //topHeaderfirstMenu
    .header__logo.-topLogoNon {
      display: none;
    }
  } //header
  &.-spMenuOpen {
    #header {
      background-color: clr(bgColor) !important;
    }
    .header__logo.-topLogoNon {
      display: unset !important;
    }
    .-topHeaderfirst {
      .spMenuBtn__icon span {
        background-color: clr(text);
      }
      .header__logo {
        display: block !important;
      }
      .languageNav {
        li {
          a {
            color: clr(text) !important;
          }
          &:after {
            background-color: clr(text) !important;
          }
        }
      } //languageNav
    } //topHeaderfirst
  } //.-spMenuOpen
  .mv {
    position: relative;
    @include mq-pc {
      height: 100vh;
      display: flex;
    }
    @include mq-sp {
      width: 100%;
      // height: 100%;
      position: relative;
      display: block;
      // overflow: hidden;
    }
    .swiper-container {
      height: 100%;
    }
    &ImgBox {
      @include mq-pc {
        width: (920 / 1440 * 100) + vw;
      }
      @include mq-sp {
        // position: absolute;
        width: 100%;
        // height: 52.4vh;
        bottom: 0;
        height: 52.4%;
      }
    } //ImgBox
    &Txt {
      &Box {
        background-color: clr(bgColor);
        @include mq-pc {
          width: (520 / 1440 * 100) + vw;
          align-items: center;
          display: flex;
          justify-content: center;
        }
        @include mq-sp {
          // position: absolute;
          width: 100%;
          height: 47.6%;
          // height: 47.6vh;
          top: 0;
          display: flex;
          align-items: center;
        }
      }
      &.-list {
        position: absolute;
        display: flex;
        @include mq-pc {
          left: (45 / 1440 * 100) + vw;
          top: (57 / 1440 * 100) + vw;
        }
        @media screen and (min-width: $wide-basis) {
          left: 4.5rem;
          top: 5.7rem;
        }
      } //list
      &.-item {
        letter-spacing: 0.2em;
        @include mq-pc {
          font-size: (13 / 1440 * 100) + vw;
        }
        @media screen and (min-width: $wide-basis) {
          font-size: 1.3rem;
        }
      } //-item
    } //txt

    &Logo {
      margin-left: auto;
      margin-right: auto;
      text-align: center;
      position: relative;
      @include mq-pc {
        width: (283.24 / 1440 * 100) + vw;
        top: -2rem;
      }
      @include mq-sp {
        width: (264.51 / 375 * 100) + vw;
      }

      &.-logo {
        text-align: center;
      } //logo
      &.-img {
        @include mq-pc {
          width: (283.24 / 1440 * 100) + vw;
        }
        @media screen and (min-width: $wide-basis) {
          width: 28.324rem;
        }
      } //-img
      &.-txt {
        display: flex;
        letter-spacing: 0.05em;
        justify-content: center;
        line-height: 1;
        white-space: nowrap;
        @include mq-pc {
          margin-bottom: 5rem;
          font-size: (13 / 1440 * 100) + vw;
        }
        @media screen and (min-width: $wide-basis) {
          font-size: 1.3rem;
        }
        @include mq-sp {
          font-size: (13 / 375 * 100) + vw;
          margin-bottom: (38 / 375 * 100) + vw;
        }
        .left {
          @include mq-pc {
            margin-right: (13 / 1440 * 100) + vw;
          }
          @media screen and (min-width: $wide-basis) {
            margin-right: 1.3rem;
          }
          @include mq-sp {
            margin-right: (8 / 375 * 100) + vw;
          }
        }
      } //-txt
    } //Logo
  } //mv
  .section {
    &__intro {
      letter-spacing: 0.2em;
      width: 100%;
      text-align: center;
      @include mq-pc {
        margin-top: 18.625rem;
        margin-bottom: 18.625rem;
        font-size: 2.2rem;
        // max-width: (735 / 1440 * 100) + vw;
        margin-left: auto;
        margin-right: auto;
      }
      @media screen and (min-width: $wide-basis) {
        // max-width: 73.5rem;
      }
      @include mq-sp {
        font-size: (18 / 375 * 100) + vw;
        margin-bottom: (68.75 / 375 * 100) + vw;
        margin-top: (68.75 / 375 * 100) + vw;
        // width: (256 / 375 * 100) + vw;
        margin-left: auto;
        margin-right: auto;
        width: 75vw;
        text-align: left;
      }
    } //__intro
    &__about,
    &__hikibaku {
      margin-right: auto;
      margin-left: auto;
      width: 100%;
      @include mq-pc {
        max-width: $mediumContent-inner;
        margin-bottom: 17.5rem;
      }
      @media screen and (min-width: $wide-basis) {
        max-width: $mediumContent-inner-wide;
      }

      @include mq-sp {
        margin-bottom: (75 / 375 * 100) + vw;
      }
      .con {
        &.-wrap {
          display: flex;
          align-items: center;
          @include mq-sp {
            flex-wrap: wrap;
            flex-direction: column;
          }
        } //wrap
        &.-txtWrap {
          @include mq-pc {
            width: ((1200 / 3) / 1440 * 100) + vw;
          }
          @include mq-sp {
            padding-right: $padding_rl-sp;
            padding-left: $padding_rl-sp;
            order: 2;
          }
        } //txtWrap
        &.-img {
          @include mq-pc {
            width: (((1200 / 3) * 2) / 1440 * 100) + vw;
          }
          @include mq-sp {
            order: 1;
            margin-bottom: (50 / 375 * 100) + vw;
          }
        } //img
        &.section__title {
          @include mq-pc {
            margin-bottom: 9rem;
          }
          @include mq-sp {
            margin-bottom: (33.25 / 375 * 100) + vw;
          }
        }
        .moreLink {
          @include mq-pc {
            margin-top: 8.3rem;
          }
          @include mq-sp {
            margin-top: (31.25 / 375 * 100) + vw;
          }
        }
      } //con
    } //__about&__hikibaku
    &__about {
      .section__title {
        @include mq-pc {
          margin-bottom: 8.3rem;
        }
        @include mq-sp {
          margin-bottom: 9vw;
        }
      }
      .con {
        &.-txtWrap {
          @include mq-pc {
            padding-right: (112 / 1440 * 100) + vw;
          }
        } //txtWrap
      }
    } //__about
    &__hikibaku {
      .con {
        &.-txtWrap {
          @include mq-pc {
            padding-left: (112 / 1440 * 100) + vw;
          }
        } //txtWrap
      }
    } //hikibaku
    &__works {
      color: white;
      background-color: #1a1a1a;
      @include mq-pc {
        padding-top: 12.5rem;
        padding-bottom: 15rem;
        margin-bottom: 15rem;
      }
      @include mq-sp {
        padding-top: (63 / 375 * 100) + vw;
        padding-bottom: (60 / 375 * 100) + vw;
        margin-bottom: (75 / 375 * 100) + vw;
      }
      .moreLinkTxt {
        color: white;
        @include mq-pc {
          margin-left: (75 / 1440 * 100) + vw;
        }
      } //moreLinkTxt
      .moreLink {
        @include mq-sp {
          margin-left: auto;
        }
      } //moreLink
      .titleMoreLinkWrap {
        display: flex;
        align-items: center;
        @include mq-pc {
          margin-bottom: 13rem;
        }
        @include mq-sp {
          margin-bottom: (65 / 375 * 100) + vw;
        }
      } //titleMoreLinkWrap
      .section__title {
        margin-bottom: 0;
      }
      .imgWrap {
        @include mq-sp {
          overflow: hidden;
          min-height: (217 / 375 * 100) + vw;
          max-height: (217 / 375 * 100) + vw;
        }
      }
      .imgItem {
        position: relative;
        a {
          display: block;
        }
        &:not(:last-child) {
          @include mq-pc {
            margin-bottom: 12.5rem;
          }
          @include mq-sp {
            margin-bottom: (58.25 / 375 * 100) + vw;
          }
        }
        &:nth-child(odd) {
          @include mq-pc {
            margin-right: auto;
          }
          .caption {
            @include mq-pc {
              right: -2.8rem;
            }
          }
        }
        &:nth-child(even) {
          @include mq-pc {
            margin-left: auto;
          }
          .caption {
            @include mq-pc {
              left: -2.8rem;
            }
          }
        }
        &.-yoko {
          @include mq-pc {
            width: (((1200 / 3) * 2) / 1440 * 100) + vw;
          }
          @media screen and (min-width: $wide-basis) {
            width: 83rem;
          }
        } //yoko
        &.-tate {
          @include mq-pc {
            width: (586 / 1440 * 100) + vw;
          }
          @media screen and (min-width: $wide-basis) {
            width: 61.7rem;
          }
        } //tate
        .caption {
          color: white;
          @include mq-pc {
            writing-mode: vertical-rl;
            top: 0;
            position: absolute;
            line-height: 1.25;
            letter-spacing: 0.2em;
            font-size: 1.3rem;
          }
          @include mq-sp {
            margin-top: (11.25 / 375 * 100) + vw;
          }
        } //caption
      } //imgItem
    } //__works
    &__contact {
      @include mq-pc {
        margin-bottom: 17.3rem;
      }
      @include mq-sp {
        margin-bottom: (78 / 375 * 100) + vw;
      }
      .section__title {
        @include mq-pc {
          margin-bottom: 6.8rem;
        }
        @include mq-sp {
          // margin-bottom: (38.25 / 375 * 100) + vw;
          margin-bottom: 10.5vw;
        }
      } //section__title
    } //__contact
    &__insta {
      .section__title {
        margin-bottom: 0;
        letter-spacing: 0.05em;
      }
      .titleInstaIdImgWrap {
        @include mq-pc {
          margin-bottom: 7.5rem;
          display: flex;
          align-items: end;
        }
        @include mq-sp {
          margin-bottom: (45 / 375 * 100) + vw;
        }
        figure {
          display: flex;
          @include mq-pc {
            margin-left: 7.3rem;
            max-width: 22.5rem;
            width: 100%;
            height: 100%;
          }
          @include mq-sp {
            margin-top: (17 / 375 * 100) + vw;
            width: (208 / 375 * 100) + vw;
            // padding-right: 2vw;
          }
          a {
            display: flex;
          }
          img {
            width: 100%;
          }
        }
      } //titleInstaIdImgWrap
      .instaCon {
        .instaItem {
          &.-list {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            @include mq-pc {
              margin: -(15 / 1440 * 100) + vw;
            }
            @media screen and (min-width: $wide-basis) {
              margin: -1.5rem;
            }
            @include mq-sp {
              margin: -(7.75 / 375 * 100) + vw;
            }
          } //-list
          &.-item {
            overflow: hidden;
            @include mq-pc {
              width: ((1200 / 5) / 1440 * 100) + vw;
              height: ((1200 / 5) / 1440 * 100) + vw;
              padding: (15 / 1440 * 100) + vw;
            }
            @media screen and (min-width: $wide-basis) {
              width: ((1200 / 5) * 0.1) + rem;
              height: ((1200 / 5) * 0.1) + rem;
              padding: 1.5rem;
            }
            @include mq-sp {
              width: (168.76/ 375 * 100) + vw;
              height: (168.76 / 375 * 100) + vw;
              padding: (7.5 / 375 * 100) + vw;
            }

            a {
              display: block;
              width: 100%;
              height: 100%;
            }
            @include mq-sp {
              &:nth-child(n + 5) {
                display: none !important;
              }
            }
          }
        } //instaItem
      } //instaCon
    } //__insta
  } //section
  .-contactBox {
    .btnWrap {
      @include mq-pc {
        margin-top: 6.3rem;
      }
      @include mq-sp {
        margin-top: (33.25 / 375 * 100) + vw;
      }
    }
  }
  .footer__contents {
    @include mq-pc {
      padding-top: 22.5rem;
    }
    @include mq-sp {
      padding-top: (150 / 375 * 100) + vw;
    }
  }
} //index
